.SelectProductType-tabs {
    text-align: center;
    width: 95%;
    height: auto;
    min-height: 400px;
    margin: 1.5rem auto 1.5rem;
    border-radius: 2rem;
    @media (max-width: 769px) {
        padding: 2rem 0;
    }
}

.SelectProductType-nav {
    width: 100%;
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    font-size: 1.2vw;
    justify-content: space-between;
    border: 1px solid #d8c05f87;
    border-radius: 2rem;
    padding-left: 0px;
    @media (max-width: 768px) {
        width: 90%;
    }
}

.SelectProductType-nav li {
    font-size: small;
    font-weight: 900;
    width: 50%;
    padding: 0.5rem;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
    border-radius: 2rem;
    color: #8d6e63;
}

.SelectProductType-nav li:hover {
    border-radius: 200px;
    border: 1px #d8c05f87 solid;
}
.SelectProductType-nav li.active {
    border-radius: 200px;
    border: 2px #c5b471 outset;
}

.SelectProductType-text {
    padding: 0 20px 0 20px;
    color: #8d6e63;
    text-align: center;
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin: 2%;
}

.SelectProductType-bounceLeft {
    /* background-image: linear-gradient(45deg, #d8c05f50, #d8c05f00, #d8c05f50); */
    animation: bounceLeft 3s ease-in-out;
    animation-iteration-count: 2;
}

@keyframes bounceLeft {
    0%, 20%, 50%, 80%, 100% {
        transform: translateX(0);
    }
    40% {
        transform: translateX(-15px);
    }
    60% {
        transform: translateX(-8px);
    }
}

.SelectProductType-bounceRight {
    /* background-image: linear-gradient(45deg, #d8c05f50, #d8c05f00, #d8c05f50); */
    animation: bounceRight 3s ease-in-out;
    animation-iteration-count: 2;
}

@keyframes bounceRight {
    0%, 20%, 50%, 80%, 100% {
        transform: translateX(0);
    }
    40% {
        transform: translateX(15px);
    }
    60% {
        transform: translateX(8px);
    }
}
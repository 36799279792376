.SizingModal-tabs {
    width: 80%;
    height: auto;
    margin: 2rem auto 0;
    color: #8d6e63;
}

.SizingModal-nav {
    width: 100%;
    padding-left: 0px;
    display: flex;
    align-items: center;
    font-size: calc(10px + 1vw);
    justify-content: space-between;
    border: 1px solid #ac8f1a;
    border-radius: 2rem;
}

.SizingModal-nav li {
    width: 50%;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
}

.SizingModal-nav li:hover {
    border-radius: 200px;
    border: 1px #d8c05f87 solid;
}
.SizingModal-nav li.active {
    border-radius: 200px;
    border: 2px #c5b471 outset;
}

.SizingModal-h1 {
    font-weight: 600;
    font-size: calc(8px + 0.8vw);
    text-transform: uppercase;
    letter-spacing: 3px;
}

.SizingModal-h2 {
    font-weight: 600;
    color: #8d6e63;
    font-size: calc(5px + 0.8vw);
    text-transform: uppercase;
}

.SizingModalTable {
    display: inline-block;
    align-items: center;
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-family: 'Nunito';
    font-size: calc(5px + 0.8vw);
}

.SizingModalText {
    font-weight: 600;
    font-size: calc(5px + 0.5vw);
    text-transform: uppercase;
    letter-spacing: 3px;
    font-family: 'Nunito';
    color:#8d6e63;
}
.DesignCard-Container {
    position: relative;
    text-align: center;
}

.DesignCard-Overlay {
    background-color: #ffeadb60;
    backdrop-filter: blur(20px);
    width: 90%;
    padding: 8px;
    position: absolute;
    border-radius: 0.5em;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.DesignCard-image {
    border-radius: 0.5rem;
    max-width: 90%;
}
.MagnifyImageContainer {
    position: relative;
    height: auto;
    width: 100%;
    overflow: hidden;
}

.MagnifyImage {
    height: 100%;
    width: 100%;
    border-radius: 0.3rem;
}

.MagnifyBGImage {
    position: absolute;
    pointer-events: none;
    /* reduce opacity to verify position */
    opacity: 1;
    border: 1px solid #8d6e63;
    /* border-radius: 100%; */
    background-color: #ffeadb;
    background-repeat: no-repeat;
}
.Typewriter span {
    display: inline-block;
    margin: 0 0.05em;
    text-transform: uppercase;
    font-size: 4vmax;
    font-family: Yeseva One;
}
.loading02 span {
    animation: loading02 1.5s infinite alternate;
}
.loading02 span:nth-child(1) {
    animation-delay: 0s;
}
.loading02 span:nth-child(2) {
    animation-delay: 0.1s;
}
.loading02 span:nth-child(3) {
    animation-delay: 0.2s;
}
.loading02 span:nth-child(4) {
    animation-delay: 0.3s;
}
.loading02 span:nth-child(5) {
    animation-delay: 0.4s;
}
.loading02 span:nth-child(6) {
    animation-delay: 0.5s;
}
.loading02 span:nth-child(7) {
    animation-delay: 0.6s;
}
.loading02 span:nth-child(8) {
    animation-delay: 0.7s;
}
.loading02 span:nth-child(9) {
    animation-delay: 0.8s;
}
.loading02 span:nth-child(10) {
    animation-delay: 0.9s;
}
.loading02 span:nth-child(11) {
    animation-delay: 1s;
}
@keyframes loading02 {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0.1;
   }
}

.Typewriter-text {
    font-size: calc(10px + 2vw);
    backdrop-filter: blur(10px);
    padding: 1% 2%;
    margin-top: 2vw;
    border-radius: 0.5rem;
    color: #8d6e63;
    background-color: #ffeadb60;
    text-align: center;
}

.Typewriter-img-xmas {
    height: calc(150px + 3vw);
    margin-top: 50px;
    border-radius: 1rem;
}

.Typewriter-img {
    height: calc(220px + 5vw);
    margin-top: 50px;
    border-radius: 1rem;
}

.Typewriter-cursor {
    animation: blink 1.2s linear infinite;
}
@-webkit-keyframes blink {
    0% {
        opacity: 0.1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes blink {
    0% {
        opacity: 0.1;
    }
    100% {
        opacity: 1;
    }
}
.App {
    text-align: center;
    background:#ffeadb;
    /* background: linear-gradient(#000000, #F5F3F1 50%, #000000); */
    /* background-color: #F5F3F1; */
    color: #8d6e63;
    font-weight: 600;
    min-height: 100vh;
    padding-top: 80px;
    font-size: calc(10px + 1vw);
}

.App-text {
    font-size: 1.2vmax;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin: 5%;
}

.colors {
    color:#21565a;
    color: #dcd6f7;
    color:#ffeadb;
    color: #8d6e63;
}

a {
    color: #21565a;
}
a:hover{
    color: #8d6e63;
}
a:link {
    text-decoration: none;
}

h3 {
    font-size: calc(10px + 2vw);
    color: #21565a;
}
h4 {
    font-size: calc(8px + 1vw);
    color: #21565a;
}
h5 {
    font-size: calc(8px + 1vw);
    color: #21565a;
}
h6 {
    font-size: calc(8px + 1vw);
    color: #21565a;
}

body {
    /* font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif, 'Yeseva One', 'Nunito','Poiret One'; */
    /* font-family:'Yeseva One'; */
    font-family:'Poiret One';
}

.buttonStyle-accent {
    /* color: #21565a; */
    color: #8d6e63;
    width: 100%;
    font-size: calc(8px + 0.5vw);
    font-weight: 900;
    backdrop-filter: blur(20px);
    background-color:#ffeadb70;
    border-style: outset;
    border-color: #8d6e63;
    box-shadow: -5px 2px 10px 1px #8d6e6390;
    border-radius: 1rem;
    /* font-family: 'Nunito'; */
    font-family:"Poiret One";
}

.buttonStyle-accent:hover {
    color: #21565a;
    border-style: inset;
    border-color: #8d6e63;
    box-shadow: 1px 1px 3px 1px inset #8d6e6390;
}

.buttonStyle-lavender {
    font-size: calc(8px + 0.5vw);
    color:#8d6e63;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #dcd6f750;
    border-radius: 2rem;
    border: 2px #8d6e63 double;
    border-color: #8d6e63;
    box-shadow: -2px 1px 8px 1px #8d6e6390;
    font-family: 'Nunito';
}

.buttonStyle-lavender:hover {
    /* border: 2px #dcd6f7 inset; */
    box-shadow: 1px 1px 3px 1px inset #8d6e6390;
}
/* .buttonStyle-lavender:focus {
    box-shadow: 1px 1px 3px 1px inset #8d6e6390;
    border: none;
    background: transparent;
} */

.buttonStyle-brown {
    font-size: calc(8px + 0.3vw);
    color:#8d6e63;
    font-weight: 600;
    border-radius: 2rem;
    border: 2px #8d6e63 double;
    border-color: #8d6e63;
    box-shadow: -2px 1px 8px 1px #8d6e6390;
    font-family: 'Nunito';
}

.buttonStyle-brown:hover {
    box-shadow: 1px 1px 3px 1px inset #8d6e6390;
}

.buttonStyle-brown-solid {
    font-size: calc(8px + 0.3vw);
    background-color:#8d6e63;
    color:#ffeadb;
    font-weight: 600;
    border-radius: 1rem;
    border: 2px #8d6e63 double;
    border-color: #8d6e63;
    box-shadow: -2px 1px 8px 1px #8d6e6390;
    font-family: 'Nunito';
    width: 100%;
}

.buttonStyle-brown-solid:hover {
    box-shadow: 1px 1px 3px 1px inset #8d6e6390;
    background-color:#8d6e6380;
}

.buttonStyle-white {
    color: white;
    border-radius: 2rem;
    border: 4px #ffffff double;
    padding: 3% 15% 3% 15%;
    font-weight: 900;
    font-size: calc(8px + 0.8vw);
    text-transform: uppercase;
    margin-top: 5%;
    min-width: max-content;
    /* background-color: #6c5a8980; */
    /* font-family: "Yeseva One"; */
    /* background: linear-gradient(45deg, #F5F3F180, #00000080 80%); */
}

.buttonStyle-white:hover {
    color: white;
    border: 4px white outset;
    /* background: linear-gradient(45deg, #00000080, #6c5a8980); */
}

.buttonStyle-red {
    color: white;
    border-radius: 25px;
    border: 3px #6c5a8980 double;
    font-weight: 100;
    text-transform: uppercase;
}

.buttonStyle-red:hover {
    border-radius: 2rem;
    border: 3px #6c5a8980 outset;
    background: linear-gradient(45deg, #00000080, #6c5a8980);
}

.buttonAnimate {
    background-color: transparent;
    min-width: 40%;
    font-size: 2vmax;
    -webkit-border-radius: 60px;
    border-radius: 60px;
    border: 3px #d8c05f87 double;
    color:rgb(255, 255, 255);
    cursor: pointer;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    animation: glowing 3500ms infinite;
    font-weight: 100;
    text-transform: uppercase;
}

.buttonAnimate:hover {
    border: 2px #c5b471 outset;
    color: #c5b471;
    background-color: #00000045;
}

.buttonAnimate:focus {
    border: 2px rgb(197, 180, 113) outset;
}

@keyframes glowing {
    0% {
        /* background-color: #c5b471; */
        box-shadow: 0 0 8px #d8c05f87;
    }
    /* 25% {
        background-color: #c5ba90;
        box-shadow: 0 0 20px #c5ba90;
    } */
    50% {
        /* background-color: #f4eed4; */
        box-shadow: 0 0 25px #faefbe75;
    }
    /* 75% {
        background-color: #c5ba90;
        box-shadow: 0 0 20px #c5ba90;
    } */
    100% {
        /* background-color: #c5b471; */
        box-shadow: 0 0 8px #c5b47187;
    }
}

.cursorPointer {
    cursor: pointer;
}

.displayNone {
    display: none;
}

img.hover-zoom {
    transition: all 0.3s ease 0s;
}

img.hover-zoom-light {
    transition: all 0.3s ease 0s;
}

img.hover-zoom:hover {
    transform: scale(1.2);
}

img.hover-zoom-light:hover {
    transform: scale(1.02);
}

.logo {
    margin: 15px;
    height: 5vh;
}

.nav-link {
    color: #21565a;
}
.nav-link:hover {
    color: #8d6e63;
}
.nav-link:active {
    color: #8d6e63;
}
.nav-link:focus {
    color: #8d6e63;
}

.pageHeight {
    height: auto;
}

.toastStyle-black {
    color:#000000;
    background-color: white;
    border-radius: 25px;
    border: 3px #00000087 double;
}

.toastStyle-gold {
    color:#c5b471;
    background-color: white;
    border-radius: 25px;
    border: 3px #d8c05f87 double;
}

/* CSS style for scroll bars on this app */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 2rem;
}

::-webkit-scrollbar-thumb {
    /* border: 1px solid #21565a; */
    background-color:#8d6e6380;
    border-radius: 2rem;
}

::-webkit-scrollbar-thumb:hover {
    background-color:#8d6e63;
}

.textEmboss {
    text-shadow: 1px 1px 0px #ffeadb, -1px -1px 0px #21565a;
    /* text-shadow: -1px -1px 0px #ffeadb, 1px 1px 0px #21565a; */
}

.textOutline {
    /* text-shadow: #8d6e63 0px 0px 4px; */
    /* -webkit-text-stroke: 2px #ffffff; */
    -webkit-text-stroke: 2px #ffeadb;
    color: transparent;
}

.textOutline-dark {
    /* text-shadow: #8d6e63 0px 0px 4px; */
    /* -webkit-text-stroke: 2px #ffffff; */
    -webkit-text-stroke: 2px #8d6e63;
    color: transparent;
}

/* Allows to let text scroll from side to side */
.Text-move {
    position: absolute;
    font-size: calc(8px + 0.2vw);
    padding: 2px;
    white-space: nowrap;
    font-weight: 900;
    letter-spacing: 3px;
    font-family: 'Nunito';
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    width: 100%;
    animation: textMove 40s linear infinite;
}

.Text-move-delayed {
    animation-delay: -15s;
}

@media (max-width: 992px) {
    .Text-move {
        animation: textMove 15s linear infinite;
    }
}

@media (max-width: 992px) {
    .Text-move-delayed {
        display: none;
    }
}

@keyframes textMove {
    0% {left: -10%; color: #ffeadb;}
    25% {left: 15%; color: #21565a;}
    50% {left: 40%; color: #21565a;}
    75% {left: 65%; color: #21565a;}
    100% {left: 90%; color: #ffeadb;}
}

/* creates a linear color gradient */
.background-gradient-1 {
    background: linear-gradient(120deg, #000000 10%, #4a5a67 45%, rgba(0,0,255,0) 80%);
    border-radius: 1rem;
}
.background-gradient-2 {
    background: linear-gradient(120deg, #88b0d0 10%, #4a5a67 25%, rgba(0,0,255,0) 45%);
    border-radius: 1rem;
}

/* formatting for tables */
td {
    border: 1px solid #ffeadb;
    padding: 5px 8px;
  }
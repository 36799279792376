.slide-in {
    transform: translateX(0px) !important;
    opacity: 1 !important;
    transition-duration: 2s;
}
.slide-out {
    transform: translateX(-150px);
    opacity: 0;
    transition-duration: 2s;
}

.slide-down {
    transform: translateY(100px);
    opacity: 1 !important;
    transition-duration: 2s;
    /* text-align: left; */
    /* margin-left: 10vw; */
    /* background-color: #8d6e6360;
    backdrop-filter: blur(5px); */
    /* width: 30vw;
    height: 30vw; */
    /* margin: auto; */
    /* padding: 2%; */
    /* border-radius: 100%; */
    /* margin-left: 40vw; */
}

.slide-up {
    transform: translateY(-100px);
    opacity: 0;
}
.image {
    width: 10vw;
    margin: 1px;
    border-radius: 1rem;
    position: relative;
}

@media (max-width: 992px) {
    .image {
        width: 15vw;
    }
}

@media (max-width: 768px) {
    .image {
        width: 20vw;
    }
}
@media (max-width: 576px) {
    .image {
        width: 25vw;
    }
}

.layout {
    display: inline-block;
    margin: 1vw;
}

.text {
    text-transform: uppercase;
    margin: 5px;
    border: none;
    font-size: 1vmax;
}

.radioHidden {
    position: absolute;
    opacity: 0;
    width: 0;
}

.radioHidden + .image {
    cursor: pointer;
}

.radioHidden + .image:hover {
    border-radius: 1rem;
    padding: 0.2vw;
    border: 2px #dcd6f7 double;
    border-style: inset;
    box-shadow: 1px 1px 3px 1px inset #8d6e6390;
}

.radioHidden:checked + .image {
    border-radius: 1rem;
    padding: 0.2vw;
    border: 2px #dcd6f7 double;
    border-style: inset;
    box-shadow: 1px 1px 3px 1px inset #8d6e6390;
    /* box-shadow: 3px 2px 10px #dcd6f7;
    border: 2px #dcd6f7 outset; */
}


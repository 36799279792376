.Blog-layout {
    background-size: cover;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-position: center;
    height: 30vh; /* 100% of viewport height */
    /* margin: 0 auto; */
    position: relative;
    border-radius: 1rem;
}

.Blog-overlay {
    width: 100%;
    border-radius: 0.5rem;
    border: double #ffeadb80;
    padding: 3%;
    background-color: #8d6e6380;
    backdrop-filter: blur(20px);
}

.Blog-title {
    font-size: calc(10px + 0.5vw);
    color: #ffeadb;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 900;
    font-family: 'Nunito';
}

.Blog-subtitle {
    font-size: calc(5px + 0.5vw);
    color: #ffeadb;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 900;
    font-family: 'Nunito';
    width: 90%;
    margin: auto;
}
.LoaderContainer {
    height: 15px;
    width: 20vw;
    padding: 3px;
    border: solid 1px;
    color: #8d6e63;
    background-color: transparent;
    border-radius: 2rem;
    display: inline-block;
    margin-top: 5px;
}

@media (max-width: 767px) {
    .LoaderContainer {
        width: 40vw;
    }
}

.LoaderFillerStyle {
    height: 100%;
    background-color: #21565a;
    border-radius: inherit;
    text-align: right;
    transition: width 3s ease-out;
}

.LoaderLabelStyle {
    padding: 15px;
    font-size: calc(10px + 2vw);
    color: #21565a;
    font-weight: 900;
}
.Home-background-color-m {
    background-color: rgba(0, 0, 0, 0.5);
    margin: 10%;
    padding: 2% 4%;
    border-radius: 2rem;
}

.HomeSpinner {
    animation-duration: 3s;
    width: 40px;
    height: 40px;
    color: #96f8f250;
    border: 4px #ffffff double;
    margin-top: 25vh;
}

.Home-column-height-s {
    height: 40vh;
}

.Home-column-height-m {
    height: 50vh;
}

.Home-column-height-l {
    height: 80vh;
}

.Home-img {
    margin: 0rem auto 0.2rem;
    border-radius: 1rem;
    max-height: auto;
    max-width: 70vw;
}

.Home-design-button {
    max-width: 60vw;
    font-size: 5vw;
}

.Home-title-headline {
    font-size: clamp(3vw, 5vw, 5vw);
    font-family: "Yeseva One";
}

.Home-title {
    text-transform: uppercase;
    /* font-family: "Yeseva One"; */
    /* font-weight: 900; */
    background: linear-gradient(90deg,
        #21565a,
        #34898f 25%,
        #dcd6f7 35%,
        #8d6e63 45%,
        #34898f 80%,
        #21565a 100%);

    background-size: 200% auto;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    animation: colorise 5s alternate infinite;
}
    @keyframes colorise {
        to {
            background-position: 200% center;
        }
    }

.Home-title-text {
    font-size: 2vmax;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 5px;
}
.Home-title-text-sm {
    font-size: 1.5vmax;
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.Home-title-subtext {
    color: #38ada6;
    font-size: 1.2vmax;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.Home-text {
    font-size: 1.2vmax;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin: 2%;
}

#HomeSection0 {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    height: 60vh; /* 100% of viewport height */
    margin: 0 auto;
    position: relative;
}

#HomeSection1 {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    height: 40vh; /* 100% of viewport height */
    margin: 0 auto;
    position: relative;
}

#HomeSection2 {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
    border-radius: 0.5rem;
    min-height: 50vh;
    width: 100%;
}

@media (max-width: 768px) {
    #HomeSection2 {
        background-size: 250vw;
    }
    #HomeSection1, #HomeSection0 {
        background-attachment:local;
    }
}

#HomeSection2-local {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: local;
    background-position: center;
    /* height: 50vh; */
    max-height: 768px;
    max-width: 1024px;
    margin: 0 auto;
    position: relative;
    border-radius: 0.5rem;
}

#HomeSection3 {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    height: 80vh; /* 100% of viewport height */
    margin: 0 auto;
    position: relative;
}

.HomeScrollingContainer {
    overflow: hidden;
    height: 100vh;
}

.HomeScrollingImg0 {
    /* background-image: url('/app/packages/web/public/img_loop.png'); */
    background: repeat-y;
    background-size: contain;
    height: 10000px;
    background-position: top;
    animation: move0 100s linear infinite;
}

@keyframes move0 {
    0% { transform: translateY(-5000px);}
    100% { transform: translateY(0px);}
    /* 0% { transform: translateY(0px);}
    100% { transform: translateY(-5000px);} */
}

.HomeScrollingImg1 {
    /* background-image: url('/app/packages/web/public/img_loop.png'); */
    background: repeat-y;
    background-size: contain;
    height: 10000px;
    background-position: top;
    animation: move1 150s linear infinite;
}

@keyframes move1 {
    0% { transform: translateY(-5000px);}
    100% { transform: translateY(0px);}
}

.HomeScrollingImg2 {
    /* background-image: url('/app/packages/web/public/img_loop.png'); */
    background: repeat-y;
    background-size: contain;
    height: 10000px;
    background-position: top;
    animation: move2 100s linear infinite;
}

@keyframes move2 {
    0% { transform: translateY(-5000px);}
    100% { transform: translateY(0px);}
}

/* .Home-gallery {
    overflow: hidden;
    position: relative;
} */

.Home-gallery-img {
    object-fit: cover;
    opacity: 0.6;
    padding: 5px;
}

.Home-gallery-text {
    margin: -10% 0%;
    width: 200px;
    height: 50px;
    padding: 10px 5px;
    position: absolute;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    color: #c5b471;
    background-color: rgba(0, 0, 0, 0.3);
    font-size: 1.2vw;
    border-radius: 2rem;
}

@media (max-width: 768px) {
    .Home-gallery-text {
        font-size: 2vw;
    }
}

.Home-gallery-products {
    background: linear-gradient(-45deg, #8d6e6320 0%, #8d6e6340 25%, #ffeadb 51%, #aaa5bf60 100%);
    background-size: 200% 200%;
    border-radius: 1rem;
    margin: 0 auto;
    padding: 10px;
}
@media (min-width: 992px) {
    .Home-gallery-products {
        width: 90%;
        margin: 0 auto;
    }
}

.Home-tabs-overlay {
    width: 100%;
    color: #ffffff;
    border-radius: 2rem;
    border: double rgba(255, 255, 255, 0.4);
}

.Home-tabs {
    width: 90%;
    height: auto;
    min-height: 200px;
    margin: 1.5rem auto;
    padding: 0rem 2rem;
    border-radius: 2rem;
}
.Footer {
    padding: 5vw;
    background:
            radial-gradient(ellipse at top, #ffeadb, transparent),
            radial-gradient(ellipse at bottom, #dcd6f7, transparent);
    color: #8d6e63;
    position: sticky;
    top: 100vh;
}

.Footer-menu {
    font-weight: 900;
    text-align: left;
    font-size: calc(10px + 0.5vw);
    letter-spacing: 3px;
    font-family: 'Nunito';
    margin: 2%;
    text-transform: uppercase;
    line-height: 30px;
}

.Footer-text {
    font-weight: 900;
    text-align: left;
    font-size: calc(8px + 0.5vw);
    letter-spacing: 3px;
    font-family: 'Nunito';
    margin: 2%;
}

.Footer-text-copyright {
    font-weight: 900;
    text-align: left;
    font-size: calc(6px + 0.3vw);
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: 'Nunito';
    margin: 2%;
}
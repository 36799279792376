.OrderConfirmation-tabs {
    width: 90%;
    height: auto;
    margin: 0 auto;
    padding: 0.5rem 1rem;
}

.OrderConfirmation-Col {
    padding: 1vh 3vw;
}

.OrderConfirmation-text {
    text-align: start;
    font-weight: 600;
    font-size: calc(12px + 0.5vw);
}
@media (max-width: 768px) {
    .OrderConfirmation-text {
        font-size: 3vw;
    }
}

.OrderConfirmation-subheading {
    color: #21565a;
    font-size: calc(12px + 0.5vw);
    font-weight: 600;
    text-transform: uppercase;
    font-family: Aladin;
}
.HeroImageBG-animate {
    background: linear-gradient(-45deg, #8d6e6380 0%, #8d6e6380 25%, #ffeadb 51%, #aaa5bf 100%);
    background-size: 400% 400%;
    box-shadow: 0px 5px 10px 1px #8d6e6390;
    -webkit-animation: AnimateStudioBG 30s ease infinite;
    animation: AnimateStudioBG 30s ease infinite;
}

    @-webkit-keyframes AnimateStudioBG {
    0% {
    background-position: 0% 50%;
    }
    50% {
    background-position: 100% 50%;
    }
    100% {
    background-position: 0% 50%;
    }
}

    @keyframes AnimateStudioBG {
    0% {
    background-position: 0% 50%;
    }
    50% {
    background-position: 100% 50%;
    }
    100% {
    background-position: 0% 50%;
    }
}
.HeroImage-video-container {
    overflow: hidden;
    width: 100%;
    max-height: 45vw;
}

.HeroImageVideo-overlay {
    position: absolute;
    transform: translate(150%, -180%); /* if logo is 33vw, i.e. 100% move on x means 33vw, i.e. middle */
    z-index: 10;
    border-radius: 100%;
    background-color:#ffeadb80;
    backdrop-filter: blur(10px);
}

.HeroImage-video-button {
    backdrop-filter: blur(25px);
    color: #ffffff;
    min-width: max-content;
    width: 15vw;
    font-weight: 600;
    font-size: calc(10px + 1vw);
    letter-spacing: 0.3vw;
    border-style: outset;
    border-color: #8d6e63;
    box-shadow: -5px 2px 10px 1px #8d6e6390;
    border-radius: 1rem;
}

.HeroImage-video-button:hover {
    color: #ffffff;
    border-style: inset;
    border-color: #8d6e63;
    box-shadow: 1px 1px 3px 1px inset #8d6e6390;
}

.HeroImageMove-overlayButtonUp {
    position: relative;
    transform: translateY(20vw);
    transition-duration: 2s;
    z-index: 10;
    margin-top: -25vw;
}

.HeroImageMove-overlayButtonDown {
    position: relative;
    transform: translateY(120%);
    transition-duration: 2s;
    z-index: 10;
    margin-top: -25vw;
}

.HeroImage-container-img {
    position: relative;
}

.HeroImage-background-color {
    background-color: rgba(0, 0, 0, 0.25);
    min-width: 100%;
    min-height: 100%;
    /* border-radius: 2rem; */
}

.HeroImage-title {
    position: relative;
    /* text-align: start; */
    top: 40%;
    /* margin-left: 5vw; */
}

.HeroImage-img {
    max-width: 26%;
    max-height: 26%;
    position: absolute;
    top: 25%;
    left: 37.5%;
}

.HeroImage-text-inset {
    font-size: 3vw;
    font-weight: 600;
    background-color: #8d6e63;
    color: transparent;
    text-shadow: 2px 2px 0px rgba(255,255,255,0.6);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}


.HeroImage-design-button {
    backdrop-filter: blur(25px);
    width: 25vw;
    height: 25vw;
    border-style: outset;
    font-weight: 600;
    border-color: #8d6e63;
    box-shadow: -5px 2px 10px 1px #8d6e6390;
    border-radius: 100%;
    padding: 1% 3% 1% 3%;
    transform: translateY(200px);
}

.HeroImage-design-button:hover {
    color: #21565a;
    border-style: inset;
    border-color: #8d6e63;
    box-shadow: 1px 1px 3px 1px inset #8d6e6390;
}

.HeroImage-design-button1 {
    color: #21565a;
    width: 25vw;
    font-size: 2vw;
    font-weight: 900;
    backdrop-filter: blur(6px);
    border-style: outset;
    box-shadow: 1px 1px 3px 1px #8d6e6390;
    border-radius: 2rem;
}

.HeroImage-design-button2 {
    width: 20vw;
    height: 20vw;
    border-color: #21565a90;
    background-color: #8d6e6350;
    backdrop-filter: blur(2px);
    box-shadow: inset -30px 50px 50px 10px #21565a90, 5px 3px 20px 5px #00000090;
    border-radius: 100%;
}

.HeroImage-design-button2:hover {
    box-shadow: inset -30px 60px 50px 20px #21565a90, 0px 0px 20px 10px #00000090;
    background-color: #8d6e6320;
}

@media (max-width: 992px) {
    .HeroImage-design-button,
    .HeroImage-design-button1,
    .HeroImage-design-button2 {
        width: 40vw;
        height: 40vw;
    }
}

.HeroImage-button {
    position: relative;
    text-align: end;
    top: 45%;
}

.HeroImage-title-text {
    font-size: 4vmax;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.HeroImage-title-text-sm {
    font-size: 2.5vmax;
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.HeroImage-heart-beat {
    margin: 0em;
    animation: 1.8s infinite HomeBeatHeart;
}

@keyframes HomeBeatHeart {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.1);
    }
    40% {
        transform: scale(1);
    }
    60% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
.Gallery-container {
    position: relative;
    text-align: center;
}

.Gallery-description {
    position: absolute;
    top: 0%;
    color: white;
    text-transform: uppercase;
    font-size: 1.6vw;
    background-color: #000000cc;
    padding: 5%;
}

.Gallery-hide {
    display: none;
}

.Gallery-show:hover + .Gallery-hide, .Gallery-description:hover {
    display: block;
}
.Colour-picker {
    border: 2px solid transparent;
    background: #8d6e6330;
    border-radius: 2rem;
    width: calc(30px + 0.5vw);
    height: calc(30px + 0.5vw);
    margin: 4px;
}

.Colour-picker:hover, .Colour-picker:active, .Colour-picker:focus{
    border: 2px solid #8d6e63;
}

.Colour-filter {
    border: 2px solid #8d6e6320;
    background: transparent;
    border-radius: 2rem;
    width: calc(35px + 0.5vw);
    height: calc(35px + 0.5vw);
    margin: auto;
}

.Colour-filter:hover, .Colour-filter:active, .Colour-filter:focus{
    border: 2px solid #8d6e6350;
    border-radius: 2rem;
}

.Colour-filter-checked {
    border: 2px solid #8d6e63;
    background: #ffffff80;
    border-radius: 2rem;
    width: calc(35px + 0.5vw);
    height: calc(35px + 0.5vw);
    margin: auto;
}

.Colour-filter-col {
    border-radius: 2rem;
    width: calc(20px + 0.5vw);
    height: calc(20px + 0.5vw);
    margin: auto;
}
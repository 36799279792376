.Navigation {
    height: 70px;
}

@media (max-width: 1200px) {
    .NavHide {
        display: none;
    }
}

@media (min-width: 1200px) {
    .NavHideHome {
        display: none;
    }
    .Navigation-font {
        font-size: calc(6px + 0.5vw);
        font-weight: 900;
        letter-spacing: 2px;
        font-family: 'Nunito';
    }
    .Navigation-col1 {
        width: 40%;
    }
    .Navigation-col2 {
        width: 20%;
    }
    .Navigation-col3 {
        width: 40%;
    }
}

.NavigationOffCanvas {
    padding: 10px 5vw 10px 5vw;
}

.NavigationOffCanvas-font {
    font-size: calc(12px + 1vw);
    font-weight: 900;
    letter-spacing: 3px;
    font-family: 'Nunito';
}

@media (max-width: 1200px) {
    .Navigation-font {
        font-size: calc(12px + 0.5vw);
        font-weight: 900;
        letter-spacing: 2px;
        font-family: 'Nunito';
    }
}

.Navigation-item:hover, .Navigation-item:active, .Navigation-item:focus {
    border-bottom: solid 3px #8d6e63;
}

.Navigation-toggle-container,
.Navigation-toggle-container:focus {
    border: none;
    box-shadow: none;
    outline: none;
}

.Navigation-toggle {
    color:#21565a;
}

@media (max-width: 1200px) {
    .Navigation-toggle {
        width: 100vw;
    }
}

.Navigation-tagnav {
    height: auto;
    width: 100vw;
    position: fixed;
    z-index: 100;
    margin-top: -20px;
    backdrop-filter: blur(25px);
    background-color: #ffeadb;
}

.NavigationBgGradient {
    background:
    radial-gradient(ellipse at bottom, #ffeadb, transparent),
    radial-gradient(ellipse at top, #dcd6f7, transparent);
    backdrop-filter: blur(40px);
}

.Navigation-colorise {
    background: linear-gradient(90deg,
    #ffeadb,
    #dcd6f7 66%,
    #ffeadb 100%);
    background-size: 200% auto;
    animation: nav-colorise 8s alternate infinite;
}

@keyframes nav-colorise {
    to {
        background-position: 200% center;
    }
}

.Navigation-tagline {
    font-weight: 900;
    letter-spacing: 3px;
    color: #8d6e63;
    font-family: 'Nunito';
    font-size: calc(8px + 0.2vw);
    padding: 6px 0px 6px 0px;
}
.ContactModal-h1 {
    font-weight: 900;
    font-size: calc(5px + 1vw);
    text-transform: uppercase;
    margin-top: 15px;
    font-family: 'Nunito';
    color: #21565a;
}

.ContactModal-h2 {
    font-weight: 400;
    font-size: calc(5px + 0.8vw);
    font-family: 'Nunito';
    color: #8d6e63;
}
.ImageUpload-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment:local;
    background-position: center;
    border-radius: 1rem;
    min-width: 150px;
    min-height: 400px;
    width: 100%;
    height: 100%;
}

.ImageUpload-title {
    text-transform: uppercase;
    color: #d8c05f;
}

.ImageUploadCloud {
    animation: bounceUp 3s ease-in-out;
    animation-iteration-count: 2;
}

@keyframes bounceUp {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-15px);
    }
    60% {
        transform: translateY(-8px);
    }
}

@media (max-width: 768px) {
    .ImageUploadCloud {
        animation: none;
    }
 }
.ProductDetails-title {
    font-weight: 900;
    font-size: calc(12px + 1.2vw);
    margin-bottom: 20px;
    text-align: start;
}

.ProductDetails-text {
    font-weight: 900;
    font-size: calc(8px + 0.5vw);
    text-align: justify;
    line-height: 1.4;
}

.ProductDetails-align {
    text-align: left;
}

.ProductDetails-width {
    width: 95vw;
    margin: auto;
    margin-top: calc(70px + 0.5vw);
    padding: 2% 5%;
    /* TODO check which background col could help visibility */
    /* background:
    radial-gradient(ellipse at top, #8d6e6350 -200%, transparent),
    radial-gradient(ellipse at bottom, #ffeadb, transparent); */
    box-shadow: -1px 0px 20px 1px #8d6e6350;
    border-style: solid;
    border-radius: 0.5rem;
    border-width: 0px;
    border-color: #8d6e63;
}

.ProductDetails-allProducts {
    text-align: left;
    width: 100%;
    font-size: calc(8px + 0.3vw);
    text-transform: uppercase;
    font-weight: 600;
    border: none;
}
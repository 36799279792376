.Favourites-width {
    width: 90vw;
    /* margin: 3.5rem auto 1.5rem; */
}

.Favourites-text {
    font-size: 1.2vmax;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin: 5%;
}
.AddressSummary-text {
    /* text-align: center;
    font-size: calc(8px + 1vw);
    font-weight: 100; */
    font-size: calc(8px + 0.5vw);
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: 'Nunito';
}

.AddressSummary-subheading {
    font-size: calc(8px + 1vw);
    font-weight: 600;
    text-transform: uppercase;
}

.AddressSummary-frame {
    /* border-radius: 2rem; */
    padding: 10px;
    margin: 5px;
}

.AddressSummary-align {
    justify-content: center;
}

.AddressSummary-text-accent {
    font-weight: 900;
    color: #21565a;
    font-size: calc(6px + 0.5vw);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: 'Nunito';
}
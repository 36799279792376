.OrderSummary-text {
    font-size: calc(8px + 0.5vw);
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: 'Nunito';
}

.OrderSummary-headline {
    font-size: calc(8px + 1vw);
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: 'Nunito';
    text-align: left;
}

/* tried to add inline style code as class, but wont work */
.AiTool-align {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}

.AiTool-button {
    max-width: "22vw";
    max-height: "22vw";
    z-Index: "1";
    position: "absolute";
    background-color: transparent;
}
/* tried to add inline style code as class, but wont work */
.AiTool-close-btn {
    cursor: pointer;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 0.5%;
    right: 0.5%;
}

.AiTool-overlay {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    background-color: #dcd6f795;
    border-radius: 2rem;
    transition: 1s;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    overflow-x: hidden; /* disable horizontal scroll */
    justify-content: center;
    align-items: center;
}

.AiTool-overlay-content {
    position: relative;
    margin-top: 30vh;
    display: inline-block;
    padding: 3%;
    /* width: 90vw; */
    /* background-color: #8d6e6390; */
    /* border-radius: 2rem; */
    /* border: 4px double rgba(255, 255, 255, 0.4); */
}

.AiTool-input {
    border-radius: 1rem;
    padding: 2% 3%;
    min-height: 5vh;
    min-width: 35vw;
    text-align: center;
    font-size: 2vmax;
    font-weight: 900;
    color: #8d6e63;
    font-size: calc(16px + 0.5vw);
    /* font-family: "Yeseva One"; */
    background-color: transparent;
    outline: none;
}

@media (max-width: 768px) {
    .AiTool-input {
        min-width: 60vw;
    }
}
@media (min-width: 992px) {
    .AiTool-input {
        min-height: 8vh;
    }
}

.AiTool-style-name {
    text-transform: uppercase;
    color: #21565a;
}

.AiTool-styles {
    border-radius: 1rem;
    margin-bottom: 0.5rem;
    max-width: 80%;
}

.AiTool-caption {
    font-size: 25px;
    background-color: rgba(0, 0, 0, 0.596);
    border-radius: 1rem;
    color: white;
}

.AiTool-text-sm {
    text-transform: uppercase;
    text-align: start;
    font-size: 0.6vw;
    font-weight: 600;
}

.AiTool-text {
    text-transform: uppercase;
    text-align: start;
    font-size: 1.5vw;
    font-weight: 600;
    /* background: linear-gradient(90deg,
        #efe8c9,
        #469597 10%,
        #469597 20%,
        #5BA199 30%,
        #5BA199 40%,
        #8d7d3e 50%,
        #8d7d3e 60%,
        #c5b471 70%,
        #c5b471 80%,
        #efe8c9 90%,
        #efe8c9 100%
        );

    background-size: 200% auto;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    animation: colorise 10s alternate infinite; */
}
@keyframes colorise {
    to {
        background-position: 200% center;
    }
}

@media (min-width: 992px) {
    .AiTool-text {
        font-size: 1vw;
    }
}

h2.accordion-header {
    border: 1px solid #8d6e63;
    border-radius: 1rem;
}

h2.accordion-header:hover {
    background-color: #8d6e6350;
}

button.accordion-button {
    background-color: transparent;
    color: #8d6e63;
    font-family:'Yeseva One';
}

button.accordion-button:focus {
    background-color: transparent;
    box-shadow: none;
}

button.accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
}

/* .carousel-control-next-icon {
    animation: bounceRight 3s ease-in-out infinite;
    animation-iteration-count: 2;
}

@keyframes bounceRight {
    0%, 20%, 50%, 80%, 100% {
        transform: translateX(0);
    }
    40% {
        transform: translateX(15px);
    }
    60% {
        transform: translateX(8px);
    }
}

.carousel-control-prev {
    animation: bounceLeft 3s ease-in-out infinite;
    animation-iteration-count: 2;
}

@keyframes bounceLeft {
    0%, 20%, 50%, 80%, 100% {
        transform: translateX(0);
    }
    40% {
        transform: translateX(-15px);
    }
    60% {
        transform: translateX(-8px);
    }
} */
.Checkout-headline {
    font-size: 25px;
}

.Checkout-tabs {
    width: 95%;
    height: auto;
    margin: 0rem auto 1.5rem;
    padding: 2rem 1rem;
}

.Checkout-nav {
    width: 90%;
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #8d6e63;
    border-radius: 2rem;
    padding: 2px;
}

.Checkout-nav li {
    font-size: calc(6px + 0.5vw);
    width: 30%;
    padding: 5px;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
    border-radius: 2rem;
}

/* @media (max-width: 768px) {
    .Checkout-nav li {
        font-size: calc(8px + 1vw);
    }
} */

.Checkout-nav li:hover {
    border-radius: 200px;
    border: 1px #d8c05f87 solid;
}

.Checkout-nav li.active {
    border-radius: 200px;
    border: 2px #c5b471 outset;
}

.Checkout-nav li.disabled {
    border: none;
    cursor: default;
}
.Collection-tabs {
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    padding: 2rem 0.5rem;
    width: 95%;
    margin-top: calc(20px + 0.8vw);
}

.Collection-sticky {
    position: sticky;
    position: -webkit-sticky; /* Safari */
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    top: calc(110px + 0.8vw);
    padding-top: 20px;
    background-color: #ffeadb;
    width: 100%;
    z-index: 10;
}

.Collection-tabs-overlay {
    width: 100%;
    color: #ffffff;
    border-radius: 2rem;
    border: double rgba(255, 255, 255, 0.4);
}

.Collection-tabs-overlay-text {
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.Collection-images {
    border-radius: 0.5rem;
    width: 100%;
}

#custom-switch.form-check-input {
    background-color: #dcd6f780;
    border-color: #8d6e63;
}

#custom-switch.form-check-input:focus {
    background-color: #dcd6f780;
    border-color: #8d6e63;
    box-shadow: none;
}

#custom-switch.form-check-input:checked {
    background-color: #8d6e63;
    border-color: #8d6e63;
}

.Collection-nav {
    display: inline;
    text-transform: uppercase;
    text-align: start;
    border: none;
    background-color: transparent;
    color:#21565a;
    font-size: calc(8px + 0.5vw);
    font-weight: 600;
    margin: auto;
}

.CollectionMenu-overlay {
    position: relative;
    margin-right: 20px;
}

.CollectionMenu-radio-hidden {
    position: absolute;
    opacity: 0;
    width: 0;
}

.CollectionMenu-radio-hidden + .CollectionMenu-overlay:hover {
    cursor: pointer;
    text-decoration: underline 1px #8d6e63;
}

.CollectionMenu-radio-hidden:checked + .CollectionMenu-overlay {
    text-decoration: underline 3px #8d6e63;
}

.Collection-catalogue-text {
    color: #8d6e63;
    font-size: calc(8px + 0.3vw);
    font-weight: 900;
    font-family: Yeseva One;
    margin: 0px;
    padding: 0px;
    margin-bottom: 2.5vh;
    border: none;
}

.Collection-catalogue-text:hover {
    color: #21565a;
}

.Collection-tabs-img {
    margin: 0rem auto 0.2rem;
    border-radius: 1rem;
    max-height: 700px;
    max-width: auto;
}

@media (max-width: 1400px) {
    .Collection-tabs-img {
        max-height: 650px;
        max-width: auto;
    }
}

@media (max-width: 1200px) {
    .Collection-tabs-img {
        max-height: 560px;
        max-width: auto;
    }
}

@media (max-width: 992px) {
    .Collection-tabs-img {
        max-height: 460px;
        max-width: auto;
    }
}

@media (max-width: 769px) {
    .Collection-tabs-img {
        max-height: 350px;
        max-width: auto;
    }
}
@media (max-width: 576px) {
    .Collection-tabs-img {
        max-height: 280px;
        max-width: auto;
    }
}

@media (max-width: 460px) {
    .Collection-tabs-img {
        max-height: 220px;
        max-width: auto;
    }
}

.Collection-bg-col {
    background-color: rgba(0, 0, 0, 0.4);
}

.Collection-text {
    margin: 1%;
    font-size: calc(10px + 0.5vw);
    letter-spacing: 4px;
    font-weight: 900;
    text-align: start;
}

.Collection-heading {
    font-size: calc(10px + 0.5vw);
    padding: 1%;
}

.Collection-comingsoon {
    background: black;
    /* overflow: hidden; */
    position: relative;
    margin-bottom: 40px;
}

.Collection-comingsoon-img {
    border-radius: 2rem;
    object-fit: cover;
    opacity: 0.4;
}

.Collection-comingsoon-text {
    margin: -50% 10%;
    max-width: 200px;
    padding: 10px 5px;
    position: absolute;
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 1.5vw;
    text-align: left;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.2);
    /* font-size: 1vh; */
    border-radius: 2rem;
}
.SustainabilitySection {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    margin: 0 auto;
    border-radius: 1rem;
    min-height: max-content;
    width: 90%;
}

@media (max-width: 768px) {
    .SustainabilitySection {
        /* background-size: 250vw; */
        background-attachment: local;
    }
}

.Sustainability-tabs {
    width: 90%;
    height: auto;
    min-height: 400px;
    margin: 3.5rem auto 1.5rem;
    padding: 2rem 2rem;
    border-radius: 2rem;
    border: double #8d6e63;
}

.Sustainability-tabs-overlay {
    width: 100%;
    /* color: #8d6e63; */
    border-radius: 0.5rem;
    border: double #ffeadb80;
    /* background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(60px); */
    padding: 3%;
}

.Sustainability-tabs-overlay-text {
    font-size: calc(12px + 0.7vw);
    color: #ffeadb;
    /* text-transform: uppercase; */
    letter-spacing: 3px;
    font-weight: 600;
    font-family: 'Nunito';
}

.Sustainability-title {
    font-size: calc(8px + 1vw);
    color: #21565a;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 600;
    font-family: 'Nunito';
}

.Sustainability-heading {
    color: #ffeadb;
    font-size: calc(10px + 1.2vw);
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 900;
    font-family: 'Nunito';
}

.Sustainability-heading-sm {
    font-size: calc(8px + 0.8vw);
    color: #21565a;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 900;
    font-family: 'Nunito';
}

.Sustainability-nav {
    font-weight: 900;
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #8d6e6360;
    border-radius: 2rem;
    padding: 2px;
}

.Sustainability-nav li {
    width: 50%;
    padding: 0.5rem;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 2rem;
}

.Sustainability-nav li:hover {
    border-radius: 200px;
    border: 1px #8d6e63 solid;
}

.Sustainability-nav li.active {
    border-radius: 200px;
    border: 1px #8d6e63 outset;
}

/* .Sustainability-bg-img {
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: grid;
    place-items: center;
} */

.Sustainability-bg-col {
    background-color: #8d6e6350;
    backdrop-filter: blur(40px);
}

.Sustainability-align {
    margin-left: 2rem;
    margin-right: 2rem;
}

.Sustainability-intro {
    font-size: calc(10px + 1.5vw);
    /* text-align: center; */
    text-align: justify;
    margin: 1%;
}

.Sustainability-text {
    font-size: calc(8px + 1vw);
    text-align: justify;
}

.Sustainability-img {
    border-radius: 1rem;
}
.PrivacyPolicy-tabs {
    width: 90%;
    height: auto;
    min-height: 400px;
    margin: 3.5rem auto 1.5rem;
    padding: 2rem 2rem;
    border-radius: 2rem;
    border-style: double;
    font-family: 'Nunito';
    @media (max-width: 769px) {
        padding: 2rem 0;
    }
}

.PrivacyPolicy-text {
    text-align: justify;
    font-size: calc(8px + 1vw);
    padding: 4%;
    line-height: 1.6;
}
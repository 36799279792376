.Studio-container {
    padding-top: 0.5em;
    height: max-content;
    width: 95vw;
    border-style: solid;
    border-radius: 0.5rem;
    border-width: 0px;
    border-color: #8d6e63;
    background: linear-gradient(-45deg, #aaa5bf80 0%, #aaa5bf80 25%, #ffeadb 51%, #aaa5bf 100%);
    /* background: conic-gradient(at 0% 100%, #aaa5bf80 10%, #70ecf550 30%, #aaa5bf80 100%); */
    /* background:
    radial-gradient(ellipse at top, #ffeadb, transparent),
    radial-gradient(ellipse at bottom, #dcd6f7, transparent); */
    background-size: 400% 400%;
    /* background:
    radial-gradient(circle, #8d6e63 -200%, transparent); */
    /* radial-gradient(ellipse at top, #21565a, transparent),
    radial-gradient(ellipse at bottom, #ffeadb, transparent); */
    /* backdrop-filter: blur(6px); */
    box-shadow: -1px 0px 20px 1px #8d6e6390;
    -webkit-animation: AnimateStudioBG 30s ease infinite;
    animation: AnimateStudioBG 30s ease infinite;
}

    @-webkit-keyframes AnimateStudioBG {
    0% {
    background-position: 0% 50%;
    }
    50% {
    background-position: 100% 50%;
    }
    100% {
    background-position: 0% 50%;
    }
}

    @keyframes AnimateStudioBG {
    0% {
    background-position: 0% 50%;
    }
    50% {
    background-position: 100% 50%;
    }
    100% {
    background-position: 0% 50%;
    }
}

.Studio-options {
    padding: 2em;
    height: 100%;
    width: auto;
    border-style: solid;
    border-radius: 0.5rem;
    border-width: 0px;
    border-color: #8d6e63;
    background:
    radial-gradient(ellipse at top, #dcd6f7 -200%, transparent),
    radial-gradient(ellipse at bottom, #ffeadb, transparent);
    backdrop-filter: blur(6px);
    box-shadow: -1px 0px 20px 1px #8d6e6390;
}

.Studio-product-title {
    font-size: calc(6px + 0.5vw);
    background-color: #ffeadb80;
    border-radius: 1rem;
    padding: 2%;
    margin: 1%;
    text-transform: uppercase;
    font-weight: 900;
    font-family: 'Nunito';
}
.CartSummary-tabs-container {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    /* padding: 2rem 1rem; */
    /* margin: 1rem auto 1.5rem; */
    /* min-height: 250px; */
    /* border: double 4px #8d6e6380; */
}

.CartSummary-nav {
    width: 90%;
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    font-size: calc(6px + 0.5vw);
    justify-content: space-between;
    border: 1px solid #d8c05f87;
    border-radius: 1rem;
    padding: 2px;
    font-family: 'Nunito';
}
@media (max-width: 768px) {
    .CartSummary-nav {
        width: 100%;
    }
}

.CartSummary-nav li {
    width: 33%;
    padding: 5px;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
    border-radius: 2rem;
}

.CartSummary-nav li:hover {
    border-radius: 200px;
    border: 1px #d8c05f87 solid;
}

.CartSummary-nav li.active {
    border-radius: 200px;
    border: 2px #c5b471 outset;
}

.CartSummary-text {
    font-weight: 900;
    font-size: calc(6px + 0.5vw);
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: 'Nunito';
}

.CartSummary-text-accent {
    font-weight: 900;
    color: #21565a;
    font-size: calc(6px + 0.5vw);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: 'Nunito';
    text-align: start;
}

.CartSummary-text-total {
    font-weight: 900;
    font-size: calc(12px + 0.4vw);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: 'Nunito';
    text-align: start;
}

.CartSummary-numbers {
    font-weight: 900;
    font-size: calc(12px + 0.4vw);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: 'Nunito';
    text-align: end;
}
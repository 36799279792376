.AddressForm-layout {
    padding-left: 5vw;
    padding-right: 5vw;
}

.AddressForm-nav {
    width: 90%;
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    font-size: 1.2vw;
    justify-content: space-between;
    border: 1px solid #d8c05f87;
    border-radius: 2rem;
    padding: 2px;
}

@media (max-width: 980px) {
    .AddressForm-nav {
        width: 100%;
        font-size: 2.2vw;
    }
}

.AddressForm-nav li {
    width: 50%;
    padding: 5px;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
    border-radius: 2rem;
}

.AddressForm-nav li:hover {
    border-radius: 200px;
    border: 1px #d8c05f87 solid;
}

.AddressForm-nav li.active {
    border-radius: 200px;
    border: 2px #c5b471 outset;
}
.TShirt {
    position: relative;
    margin: 0 auto;
}

.TShirt-blank {
    width: 100%;
}

.TShirt-design {
    position:absolute;
    /* left: 0;
    width: 100%; */
}

.Tshirt-upload {
    position: absolute;
    top: 30%;
    left: 0;
    width: 100%;
}
.About-tabs {
    width: 90%;
    height: auto;
    margin: 0.5rem auto 0.5rem auto;
    padding: 1rem 1rem;
    border-radius: 2rem;
}

/* .About-tabs-overlay {
    width: 100%;
    color: #ffeadb;
    border-radius: 2rem;
    justify-content: center;
    padding: 1px;
    margin-top: 250px;
} */

.About-tabs-overlay-text {
    text-align: center;
    line-height: 0.5;
    font-weight: 400;
    font-size: 1vmax;
    margin: 4%;
}

.About-tabs-img {
    margin: 0rem auto 3rem;
    border-radius: 1rem;
}

.About-tabs-text {
    font-size: 2.5vw;
    text-align: justify;
    margin: 5%;
}

.About-gallery {
    overflow: hidden;
    position: relative;
    justify-content: center;
    margin: 5%;
}

.About-gallery-img {
    object-fit: cover;
    border: 4px #c5b471 double;
    border-radius: 100%;
    opacity: 0.8;
    padding: 0px;
}

.About-gallery-text {
    margin: -10% 0%;
    height: 50px;
    padding: 10px 10px;
    position: absolute;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    color: #ffeadb;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 1vh;
    border-radius: 2rem;
}

.About-nav {
    width: 90%;
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    font-size: 1.5vw;
    justify-content: space-between;
    border: 1px solid #ac8f1a;
    border-radius: 2rem;
    padding-left: 0px;
}

.About-bg-img {
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: grid;
    /* place-items: center; */
}

.About-bg-col {
    background-color: #ffeadb20;
    /* background-color: rgba(0, 0, 0, 0.4); */
    backdrop-filter: blur(20px);}

.About-align {
    font-weight: 100;
    font-size: 25px;
    margin-left: 2rem;
    margin-right: 2rem;
}

.About-heading {
    font-size: calc(10px + 2vw);
    font-weight: 600;
    font-family: 'Nunito';
    text-transform: uppercase;
}

.About-heading-sm {
    font-size: calc(5px + 1vw);
    font-weight: 600;
    font-family: 'Nunito';
    text-transform: uppercase;
}

.About-text {
    font-weight: 600;
    font-size: calc(10px + 1vw);
    text-align: justify;
    margin: 5%;
    font-family: 'Nunito';
}

.About-images {
    max-height: 30vw;
    border-radius: 2rem;
}
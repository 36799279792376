.ProductCard-tabs {
    height: auto;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    /* border: double 4px rgba(255, 255, 255, 0.4); */
    /* padding: 0rem 1rem; */
    /* min-height: 350px; */
}

.ProductCard-text {
    font-weight: 900;
    text-align: left;
    font-size: calc(6px + 0.5vw);
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: 'Nunito';
    color: #8d6e63;
}

.ProductAlert {
    border-radius: 1.5rem;
}